<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑分组')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本信息')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top" ref="validateFrom"
                   :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('号码')" prop="number">
                  <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('代答组')">
                  <el-switch v-model="ruleForm.transfer" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('类型')" prop="type">
                  <el-radio-group v-model="ruleForm.type">
                    <el-radio :label="1" :value="1">{{ $t('同振') }}</el-radio>
                    <el-radio :label="0" :value="0">{{ $t('顺振') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type == '0'">
                <el-form-item :label="$t('转接')">
                  <el-switch v-model="ruleForm.pick_up" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer mt15" style="float:right">
              <el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
            </span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('分组成员')" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px" label-position="top">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p class="member">{{ $t('分机成员') }}</p>
                <el-transfer v-model="rightValue" :props="{ key: 'id', label: 'dn',}" :data="leftValue" filterable
                             :titles="[$t('可用分机'), $t('成员分机')]" :button-texts="[$t('移除'), $t('添加')]"
                             :filter-placeholder="$t('搜索分机')" @change="getObject">
                </el-transfer>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  findGroup,
  updateGroup,
  groupMember,
  deleteGroupMember,
} from "@/http/group.api.js";
import {
  avaliableStation
} from '@/http/extension.api.js';
import {
  ElNotification
} from 'element-plus';

function groupEditData(state) {
  let params = {
    id: state.id
  }
  findGroup(params).then(res => {
    state.ruleForm   = res.data;
    state.rightValue = res.data.station_ids;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'groupingEditMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n();
    const state        = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        name: '',
        number: '',
        transfer: '',
        type: '',
        pick_up: '',
      },
      activeName: 'first',
      tabPosition: 'left',
      rightValue: [],
      leftValue: [],
      editLayoutData: [],
    });
    const validateFrom = ref()

    //校验规则
    const rules       = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入分组名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^[0-9]\d*$/,
            message: t('组号码只允许添加数字'),
            trigger: 'blur',
          }],
        type: [{
          required: true,
          message: t("请选择分组类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = (row) => {
      state.id = row.id;
      groupEditData(state);
      state.isShowDialog = true;
      state.activeName   = 'first';
      let params         = {
        keyword: 'group',
        id: state.id
      }
      avaliableStation(params).then(res => {
        if (res.code == 200) {
          state.leftValue = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            number: state.ruleForm.number,
            type: state.ruleForm.type,
            pick_up: state.ruleForm.pick_up,
            transfer: state.ruleForm.transfer,
          };
          updateGroup(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 右侧列表元素变化时触发
    // value右边框里面所有的key值  direction是方向(left和right)，movedKeys是移动的key值
    const getObject = (value, direction, movedKeys) => {
      // 判断移动方向
      if (direction === "right") {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        groupMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("编辑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      } else {
        state.editLayoutData = movedKeys;
        let params           = {
          id: state.id,
          members: state.editLayoutData
        }
        deleteGroupMember(params).then(res => {
          if (res.code == 200) {
            ElNotification({
              title: t("成功"),
              message: t("解绑成功"),
              type: "success",
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t('失败'),
              message: res.msg,
              type: 'error',
              duration: 2000,
            });
          }
        })
      }
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm  = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      ...toRefs(state),
      getObject,
      validateFrom,
      rules,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
}
</style>
