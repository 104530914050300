<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增分组')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('号码')" prop="number">
              <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <p class="member">分机成员</p>
            <el-transfer v-model="value" :props="{ key: 'value', label: 'desc',}" :data="data" filterable
              :titles="['可用分机', '成员分机']" :button-texts="['移除', '添加']" filter-placeholder="搜索分机">
            </el-transfer>
          </el-col> -->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('代答组')">
              <el-switch v-model="ruleForm.transfer" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio label="1" value="1">{{ $t('同振') }}</el-radio>
                <el-radio label="0" value="0">{{ $t('顺振') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="ruleForm.type == '0'">
            <el-form-item :label="$t('转接')">
              <el-switch v-model="ruleForm.pick_up" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {
  addGroup
} from '@/http/group.api.js';

export default {
  name: 'systemAddMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}          = useI18n()
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        number: '',
        transfer: 0,
        type: '1',
        pick_up: '',
      },
      activeName: 'first',
      tabPosition: 'left',
    });
    const validateFrom = ref()

    //校验规则
    const rules       = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入分组名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^[0-9]\d*$/,
            message: t('组号码只允许添加数字'),
            trigger: 'blur',
          }],
        type: [{
          required: true,
          message: t("请选择分组类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = () => {
      state.isShowDialog = true;
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            number: state.ruleForm.number,
            type: state.ruleForm.type,
            pick_up: state.ruleForm.pick_up,
            transfer: state.ruleForm.transfer,
          };
          addGroup(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.name     = '';
      state.ruleForm.number   = '';
      state.ruleForm.transfer = 0;
      state.ruleForm.type     = '1';
      state.ruleForm.pick_up  = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
  // data() {
  // 	const generateData = () => {
  // 		const data = []
  // 		for (let i = 1; i <= 15; i++) {
  // 			data.push({
  // 				value: i,
  // 				desc: `Option ${i}`,
  // 			})
  // 		}
  // 		return data
  // 	}
  // 	return {
  // 		data: generateData(),
  // 		value: [],
  // 	}
  // },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}
</style>
